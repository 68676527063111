<template>
  <a-card>
    <!-- 条件搜索 -->
    <!-- TODO 搜索无用，需要和后端沟通字段 -->
    <!-- <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="订单编号">
              <a-input v-model="queryParams.trade_no" placeholder="请输入订单编号" allow-clear @pressEnter="handleQuery" />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
              <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div> -->
    <div class="table-operations">
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          :pagination="false"
          @refresh="getList" />
      </div>
    <a-table :loading="loading" :data-source="tableData" :columns="columns" :size="tableSize" rowKey="id"
      :pagination="false">
      <div slot="amount" slot-scope="text"> {{ text | moneytransfer }} </div>
      <div slot="status" slot-scope="text"> {{ text | statusBypay }} </div>
      <div slot="notifyStatus" slot-scope="text"> {{ text | statusByNotify }} </div>
      <div slot="action" slot-scope="text, record">
        <a href="javascript:void(0)" @click="handleClick(record)" class="margin-right-sm">编辑</a>
        <a-popconfirm title="确定要删除该运费模板吗？" ok-text="确定" cancel-text="取消" @confirm="delFreight(record)">
          <a href="javascript:void(0)" style="color:red;">删除</a>
        </a-popconfirm>
      </div>
    </a-table>
    <!-- 分页 -->
    <a-pagination class="ant-table-pagination" show-size-changer show-quick-jumper :current="queryParams.pageNum"
      :total="total" :page-size="queryParams.pageSize" :showTotal="total => `共 ${total} 条`"
      @showSizeChange="onShowSizeChange" @change="changeSize" />
  </a-card>
</template>
<script>
import { tableMixin } from '@/store/table-mixin'
import * as paychannel from '@/api/pay/pay.js'
export default {
  mixins: [tableMixin],
  data() {
    return {
      loading: false,
      columns: [{
        title: '订单号',
        dataIndex: 'tradeNo',
        width: 60,
        type: 'text'
      },{
        title: '支付渠道',
        dataIndex: 'channelCode',
        width: 60,
        type: 'text'
      },{
        title: '商品标题',
        dataIndex: 'subject',
        width: 80
      },{
        title: '支付金额(元)',
        dataIndex: 'amount',
        width: 60,
        type: 'text',
        scopedSlots: { customRender: 'amount' }
      },{
        title: '支付状态',
        dataIndex: 'status',
        width: 50,
        type: 'text',
        scopedSlots: { customRender: 'status' }
      },{
        title: '回调状态',
        dataIndex: 'notifyStatus',
        width: 50,
        type: 'text',
        scopedSlots: { customRender: 'notifyStatus' }
      },{
        title: '回调时间',
        dataIndex: 'notifyTime',
        width: 50
      },{
        title: '支付时间',
        dataIndex: 'successTime',
        width: 60,
        type: 'time'
      },{
        title: '创建时间',
        dataIndex: 'createTime',
        width: 60,
        type: 'time'
      }],
      tableData: [],
      // 总条数
      total: 0,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        trade_no: ''
      },
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      paychannel.orderList(this.queryParams).then(res => {console.log('res8888888', res)
        if (res.success) {
          this.tableData = res.data.records
          this.total = res.data.total
          this.loading = false
        }
      })
    },
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams.name = ""
      this.handleQuery();
    },
    onShowSizeChange (current, pageSize) {
      this.queryParams.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParams.pageNum = current
      this.queryParams.pageSize = pageSize
      this.getList()
    },
  },
  filters: {
    // 金额由分转换成元
    moneytransfer(val) {
      let newval = (val) / 100
      return newval
    },
    // 支付状态,0-未支付,10-支付成功，20-支付退款
    statusBypay(type) {
      let str = ''
      switch (type) {
        case 0: str = '未支付';
          break;
        case 10: str = '支付成功';
          break;
        case 20: str = '支付退款';
          break;
        case 30: str = '支付关闭';
          break;
        default: str = ''
      }
      return str
    },
    // statusByNotify  通知商户支付结果的回调状态,0-未通知,10-通知成功，20-通知失败
    statusByNotify(type) {
      let str = ''
      switch (type) {
        case 1: str = '等待通知';
          break;
        case 2: str = '通知成功';
          break;
        case 3: str = '通知失败';
          break;
        default: str = ''
      }
      return str
    },
  },
}
</script>